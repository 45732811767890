import React from "react"
import { Link } from "gatsby"
import { linkResolver } from "../../util"
import { VeraImage } from "./vera-image"

const ArticlePresentation = ({ section }) => {
  const { ref } = section
  const _type = ref._type
  const slug = ref.slug.current
  const image =
    _type === "recipe"
      ? ref.article_image.article_image.asset
      : ref.article_image.asset
  const title = ref.article_title

  return (
    <Link to={linkResolver({ _type, slug })}>
      <div className="hero__primary-image-container">
        <VeraImage
          className="hero__primary-image transform-animate"
          asset={image}
          sizes={{ aspectRatio: 4 / 3 }}
        />
        <span className="hero__primary-image-read">Read About</span>
        <span className="hero__primary-image-name">{title}</span>
      </div>
    </Link>
  )
}
export default ArticlePresentation
