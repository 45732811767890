import React from "react"
import Hero from "./hero"
import SectionIntroText from "./section-intro-text"
import ProductTeaser from "./product/product-teaser"
import ArticleTeaser from "./article/article-teaser"
import PageTitle from "./page-title"
import AllTopics from "./all-topics"
import CollectionTeaser from "./collection-teaser"
import HeroSimple from "./hero-simple"
import ProductTeaserRecipe from "./product-teaser-recipe"

const DynamicComponent = ({ components }) => {
  return components
    ? components.map(component => {
        switch (component._type) {
          case "hero_banner":
            return <Hero key={component._key} {...component} />
          case "section_intro_text":
            return <SectionIntroText key={component._key} {...component} />
          case "product_teaser":
            return <ProductTeaser key={component._key} {...component} />
          case "article_teaser":
            return <ArticleTeaser key={component._key} {...component} />
          case "page_title":
            return <PageTitle key={component._key} {...component} />
          case "all_topics":
            return <AllTopics key={component._key} />
          case "collection_teaser":
            return <CollectionTeaser key={component._key} {...component} />
          case "hero_simple":
            return <HeroSimple key={component._key} {...component} />
          case "product_teaser_w_recipe":
            return <ProductTeaserRecipe key={component._key} {...component} />
          default:
            return null
        }
      })
    : null
}

export default DynamicComponent
