import React from "react"
import { bgColorResolver, colorResolver, linkResolver } from "../../util"
import AnchorButton from "../elements/anchor-button"
import { VeraImageHigh } from "../elements/vera-image"

const CollectionTeaser = ({ collection_references, ...props }) => {
  const teasers = collection_references.map(teaser => {
    const background = bgColorResolver(teaser.color.section_background)
    const text = colorResolver(teaser.color.section_background)
    return (
      <div key={teaser._id} className={`column ${background} ${text}`}>
        <div className="collection-teaser">
          <h3 className="collection-teaser__heading">{teaser.pitch}</h3>
          <div className="columns is-mobile">
            <div className="column is-7">
              <VeraImageHigh asset={teaser.featured_image.asset} contain />
            </div>

            <div className="column is-3" style={{ position: "relative" }}>
              <div className="collection-teaser__cta">
                <AnchorButton
                  className={`${text ? text : ""} ${background}`}
                  inverse={text === "has-text-white" ? true : false}
                  full
                  narrow
                  to={`/shop/${teaser.slug.current}`}
                >
                  {teaser.collection_title}
                </AnchorButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  })

  return (
    <section className="section">
      <div className="container">
        <div className="columns is-gapless">{teasers}</div>
      </div>
    </section>
  )
}

export default CollectionTeaser
