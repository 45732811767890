import React from "react"
import Product from "./product/product"
import RecipeTeaser from "./recipe-teaser"

const ProductTeaserRecipe = props => {
  const { title, sub_title, refs, recipe } = props
  return (
    <section className="section">
      <div className="container">
        <div className="columns">
          <div className="column is-10-tablet is-offset-1-tablet is-half-desktop is-offset-3-desktop has-text-centered">
            <h2 className="h3">{title}</h2>
            <p className="sub-header">{sub_title}</p>
          </div>
        </div>
        <div
          className="columns is-mobile is-multiline is-centered is-vcentered"
          style={{ marginBottom: "30px" }}
        >
          {refs && refs.length > 0 && (
            <>
              {refs.map(item => (
                <div
                  key={item._id}
                  className="column is-half-mobile is-one-quarter-tablet"
                >
                  <Product none {...item} />
                </div>
              ))}
            </>
          )}
          {recipe && (
            <div className="column is-full-mobile is-half-tablet">
              <RecipeTeaser
                slug={recipe.slug.current}
                asset={recipe.recipe_image_image}
                title={recipe.recipe_title}
              />
            </div>
          )}
        </div>
      </div>
    </section>
  )
}

export default ProductTeaserRecipe
