import React from "react"
import { Link } from "gatsby"
import { colorResolver, bgColorResolver, linkResolver } from "../../util"

const SectionIntroText = props => {
  const {
    section_color,
    section_link,
    section_link_text,
    section_text,
    section_title,
  } = props

  const bg = section_color
    ? bgColorResolver(section_color.section_background)
    : ""
  const fontColor = section_color
    ? colorResolver(section_color.section_background)
    : ""
  return (
    <section className={`section section-intro-text ${bg}`}>
      <div className="container">
        <div className={`columns ${fontColor}`}>
          <div className="column is-8-desktop is-offset-2-desktop is-12-tablet">
            <div className="columns">
              <div className="column is-one-third">
                <div className="section-intro-text__title-text">
                  {section_title}
                </div>
              </div>
              <div className="column is-two-third">
                <div className="section-intro-text__body-text">
                  {section_text}
                </div>
              </div>
            </div>
            {section_link && section_link_text && (
              <div className="columns">
                <div className="column">
                  <Link
                    to={linkResolver({
                      _type: section_link._type,
                      slug: section_link.slug.current,
                    })}
                    className={`${fontColor} section-intro-text__link`}
                  >
                    <span className="section-intro-text__cta-wrapper has-text-white">
                      {section_link_text}
                    </span>
                  </Link>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  )
}

export default SectionIntroText
