import React from "react"
import Article from "./article"
import SectionCta from "../../elements/section-cta"

const ArticleTeaser = ({ cta_section, article_teaser_references }) => {
  const ctaSectionMobile = cta_section &&
    cta_section.section_cta_cta &&
    cta_section.section_cta_cta.cta_link &&
    cta_section.section_cta_cta.cta_text &&
    cta_section.section_cta_paragraph &&
    cta_section.section_cta_title && (
      <div className="columns is-hidden-desktop">
        <div className="column has-text-centered">
          <SectionCta
            section_cta_cta={cta_section.section_cta_cta}
            section_cta_title={cta_section.section_cta_title}
            section_cta_paragraph={cta_section.section_cta_paragraph}
          />
        </div>
      </div>
    )

  const ctaSectionDesktop = cta_section &&
    cta_section.section_cta_cta &&
    cta_section.section_cta_paragraph &&
    cta_section.section_cta_title && (
      <div
        className="is-hidden-tablet-only is-hidden-mobile has-text-centered"
        style={{ marginBottom: "2.5rem" }}
      >
        <SectionCta
          section_cta_cta={cta_section.section_cta_cta}
          section_cta_title={cta_section.section_cta_title}
          section_cta_paragraph={cta_section.section_cta_paragraph}
        />
      </div>
    )

  const articleSection =
    article_teaser_references.length === 3 ? (
      <div
        className={`columns has-wide-margin ${
          cta_section &&
          cta_section.section_cta_cta &&
          cta_section.section_cta_paragraph &&
          cta_section.section_cta_title
            ? "is-vertical-center"
            : ""
        }`}
      >
        {article_teaser_references.map((article, i) => (
          <div
            key={`${i}_${article._id}`}
            className="column is-one-third article-teaser__article"
          >
            {i === 1 && ctaSectionDesktop}
            <Article {...article} />
          </div>
        ))}
      </div>
    ) : null

  return (
    <section className="section">
      {ctaSectionMobile}
      <div className="container">{articleSection}</div>
    </section>
  )
}

export default ArticleTeaser
