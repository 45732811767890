import React from "react"
import { Link } from "gatsby"
import { linkResolver, bgColorResolver } from "../../util"
import { VeraImage } from "./vera-image"

const ProductPresentation = ({
  _type,
  slug,
  asset,
  color,
  pitch,
  isPrimary,
  productSlug,
}) => {
  return (
    <Link to={linkResolver({ _type, slug, productSlug })}>
      <div
        className={`hero__primary-product-wrapper ${bgColorResolver(color)}`}
      >
        <VeraImage className="transform-animate" asset={asset} contain />
      </div>
      <h3 className={`article-teaser__header ${isPrimary ? "is-primary" : ""}`}>
        {pitch}
      </h3>
    </Link>
  )
}

export default ProductPresentation
