import React from "react"
import Product from "./product"
import SectionCta from "../../elements/section-cta"

const ProductTeaser = ({ refs, cta_section }) => {
  return (
    <section className="section">
      <div className="container">
        {cta_section &&
          // cta_section.section_cta_cta &&
          // cta_section.section_cta_cta.cta_link &&
          // cta_section.section_cta_cta.cta_text &&
          cta_section.section_cta_paragraph &&
          cta_section.section_cta_title && (
            <div className="columns" style={{ marginBottom: "2rem" }}>
              <div className="column is-8-desktop is-offset-2-desktop is-half-widescreen is-offset-one-quarter-widescreen is-10-tablet is-offset-1-tablet has-text-centered">
                <SectionCta {...cta_section} />
              </div>
            </div>
          )}
        <div className="columns is-multiline is-centered is-mobile">
          {refs.map((product, i) => (
            <div
              key={`${i}_${product.id}`}
              className="column is-half-mobile is-one-quarter-tablet"
            >
              <Product {...product} />
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}
export default ProductTeaser
