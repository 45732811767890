import React from "react"
import { bgColorResolver, linkResolver } from "../../util"
import { VeraImage } from "../elements/vera-image"
import AnchorButton from "../elements/anchor-button"
import { Link } from "gatsby"

const HeroSimple = ({ title, cta_title, collection_reference }) => {
  const { color, slug, featured_image } = collection_reference
  return (
    <>
      <section className="section hero-simple">
        <div className="hero-simple__background has-background-light"></div>
        <div className="container">
          <div className="columns">
            <div className="column is-hidden-mobile">
              <div
                className={`hero-simple__primary-image ${bgColorResolver(
                  color.section_background
                )}`}
              >
                <Link
                  to={linkResolver({
                    _type: "collection",
                    slug: slug.current,
                  })}
                >
                  <VeraImage asset={featured_image.asset} contain />
                </Link>
              </div>
            </div>
            <div className="column">
              <div className="hero-simple__content-wrapper-right">
                <h1 className="title">{title}</h1>
                <span className="hero-simple__cta-wrapper">
                  <AnchorButton
                    to={linkResolver({
                      _type: "collection",
                      slug: slug.current,
                    })}
                  >
                    {cta_title}
                  </AnchorButton>
                </span>
              </div>
            </div>
            <div className="column is-hidden-tablet">
              <div
                className={`hero-simple__primary-image ${bgColorResolver(
                  color.section_background
                )}`}
              >
                <VeraImage asset={featured_image.asset} contain />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default HeroSimple
