import React from "react"
import AnchorButton from "../elements/anchor-button"
import { linkResolver } from "../../util"
import ArticlePresentation from "../elements/article-presentation"
import ProductPresentation from "../elements/product-presentation"
import Article from "./article/article"

const Hero = ({
  hero_title,
  hero_primary_section,
  hero_cta,
  hero_secondary_section,
}) => {
  if (hero_primary_section.length < 2) {
    return null
  }

  const primarySection = hero_primary_section.map(section => {
    if (section.ref._type === "article" || section.ref._type === "recipe") {
      return <ArticlePresentation section={section} />
    } else if (section.ref._type === "collection") {
      return (
        <ProductPresentation
          _type={section.ref._type}
          isPrimary
          slug={section.ref.slug.current}
          asset={section.ref.featured_image.asset}
          color={section.ref.color.section_background}
          pitch={section.pitch}
        />
      )
    } else if (section.ref._type === "product") {
      return (
        <ProductPresentation
          _type="collection"
          isPrimary
          slug={section.ref.product_collection.slug.current}
          asset={section.ref.product_image.asset}
          color={section.ref.product_preview_color.section_background}
          pitch={section.pitch}
        />
      )
    }
  })

  const secondarySection = hero_secondary_section.map(section => {
    if (section.ref._type === "article" || section.ref._type === "recipe") {
      return (
        <Article
          _type={section.ref._type}
          title={section.pitch}
          image={
            section.ref._type === "article"
              ? section.ref.article_image
              : section.ref.recipe_image_image
          }
          meta={section.ref.article_meta_data}
          slug={section.ref.slug}
        />
      )
    } else if (section.ref._type === "collection") {
      return (
        <ProductPresentation
          _type={section.ref._type}
          slug={section.ref.slug.current}
          asset={section.ref.featured_image.asset}
          color={section.ref.color.section_background}
          pitch={section.pitch}
        />
      )
    } else if (section.ref._type === "product") {
      return (
        <ProductPresentation
          _type={section.ref._type}
          slug={section.ref.slug.current}
          productSlug={section.ref.product_collection.slug.current}
          asset={section.ref.product_image.asset}
          color={section.ref.product_preview_color.section_background}
          pitch={section.pitch}
        />
      )
    }
  })

  return (
    <section className="section">
      <div className="hero-simple__background has-background-light"></div>
      <div className="container">
        {/* desktop */}
        <div className="columns is-gapless">
          {/* left column */}
          <div className="column is-hidden-desktop-down">
            <div className="columns is-gapless hero__columns--left">
              <div className="column">{primarySection[0]}</div>
            </div>

            <div className="columns is-gapless">
              <div className="column">
                <div className="hero__secondary-wrapper">
                  {secondarySection[0]}
                </div>
              </div>
              <div className="column">
                <div className="hero__secondary-wrapper">
                  {secondarySection[1]}
                </div>
              </div>
            </div>
          </div>
          {/* right column */}
          <div className="column">
            <div className="columns is-gapless hero__columns">
              <div className="column is-10">
                <div className="hero__content-wrapper-right">
                  <h1 className="title">{hero_title}</h1>
                  <span className="cta-wrapper">
                    <AnchorButton
                      to={linkResolver({
                        _type: hero_cta.cta_link._type,
                        slug: hero_cta.cta_link.slug.current,
                      })}
                    >
                      {hero_cta.cta_text}
                    </AnchorButton>
                  </span>
                </div>
              </div>
            </div>
            <div className="columns is-gapless is-hidden-desktop-down">
              <div className="column">{primarySection[1]}</div>
            </div>
          </div>
        </div>

        {/* mobile */}
        <div className="columns is-gapless is-hidden-widescreen has-wide-margin">
          <div className="column">{primarySection[0]}</div>
          <div className="column">{primarySection[1]}</div>
        </div>
        <p className="hero__pitch is-hidden-tablet">
          {hero_primary_section[1].pitch}
        </p>
      </div>
    </section>
  )
}

export default Hero
