import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { linkResolver } from "../../util"

const AllTopics = () => {
  const { allSanityArticleType } = useStaticQuery(
    graphql`
      query {
        allSanityArticleType {
          nodes {
            id
            article_type_title
            slug {
              current
            }
          }
        }
      }
    `
  )

  return (
    <section className="section has-background-light is-hidden-mobile">
      <div className="container">
        <div className="columns">
          <div className="column has-text-centered is-10-tablet is-offset-1-tablet is-8-desktop is-offset-2-desktop is-half-widescreen is-offset-3-widescreen">
            <div
              className="has-text-weight-bold"
              style={{ marginBottom: ".5rem" }}
            >
              Browse all Mexican topics
            </div>
            {allSanityArticleType.nodes.map(topic => (
              <Link
                style={{
                  display: "inline-block",
                  marginBottom: ".5rem",
                  marginTop: ".5rem",
                }}
                className="popular-topics__link-btn"
                key={topic.id}
                to={linkResolver({
                  _type: "article_type",
                  slug: topic.slug.current,
                })}
              >
                {topic.article_type_title}
              </Link>
            ))}
          </div>
        </div>
      </div>
    </section>
  )
}

export default AllTopics
